import React from 'react';
import './Profile.scss';

const Profile = ({ id }) => {
	return (
		<section id={id} className="profile">
			<div className="code-content">
				<p className="code-comment">// About me</p>
				<p><span className="keyword">const</span> <span className="variable">profile</span> = <span className="brace-1">{'{'}</span></p>
				<div className="profile__content">
					<div className="profile__details">
						<p>
							I'm a software developer with a knack for turning ambitious ideas into elegant solutions. My journey into tech began 
							in 2015, when I taught myself Python to automate tasks and started building and programming racing quadcopters. That 
							early fascination with engineering eventually led me to study mechanical and mechatronics engineering, where I got 
							hands-on experience designing industrial robots and optimizing manufacturing systems.
						</p>

						<p>
							Over time, I transitioned from hardware to software, diving into web development, machine learning, and interactive 3D 
							applications. Whether it's training a model to predict wildfires, programming an AI to win board game tournaments, or 
							designing scalable web platforms, I thrive at the intersection of creativity and precision.
						</p>

						<p>
							When I'm not coding, I'm probably carving down a ski slope, tinkering with mechanical watch movements, or dreaming up my 
							next project that blurs the line between engineering and software.
						</p>
					</div>
				</div>
				<p><span className="brace-1">{'}'}</span>;</p>
			</div>
		</section>
	);
};

export default Profile;
