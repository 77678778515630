import React from 'react';
import './Card.scss'; // Assuming you're using SCSS for styles

const Card = ({ date, title, subtitle, description, tags, link, isDimmed, onHover, onLeave, isLast }) => {
	return (
		<div
			className={`card ${isDimmed ? 'dimmed' : ''} ${isLast ? 'last' : ''}`} // Apply 'dimmed' class when card should be dimmed
			onMouseEnter={onHover} // Triggers when mouse enters the card
			onMouseLeave={onLeave} // Triggers when mouse leaves the card
		>
			<span className="card__brace card__brace--open brace-3">{'{'}</span>
			<div className="card__content">
				<div className="card__date">
					<span>{date}</span>
				</div>
				<div className="card__details">
					<div className="card__header">
						<h3>
							{link ? (
								<a href={link} target="_blank" rel="noopener noreferrer" className="card__link">
									{title} ↗
								</a>
							) : (
								title
							)}
						</h3>
						{subtitle && (
							<div className="card__subtitle">
								{Array.isArray(subtitle) 
									? subtitle.map((sub, index) => <h4 key={index}>{sub}</h4>)
									: <h4>{subtitle}</h4>
								}
							</div>
						)}
					</div>
					{description && (
						<div className="card__body">
							<p>{description}</p>
						</div>
					)}
					{tags && (
						<div className="card__tags">
							{tags.map((tag, index) => (
								<span key={index} className="card__tag">
									{tag}
								</span>
							))}
						</div>
					)}
				</div>
			</div>
			<span className="card__brace card__brace--close brace-3">{'}'}</span>
			{!isLast && <span className="card__brace card__brace--comma">{','}</span>}
		</div>
	);
};

export default Card;
